import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import Container from './Container';
import SectionHeader  from "./Typography/SectionHeader"
import FacebookIcon from "../../static/facebook.svg";
import TwitterIcon from "../../static/twitter.svg";
import InstagramIcon  from "../../static/instagram.svg";
import { useForm } from "react-hook-form"
import Loader from "./Utils/Loader"
import { ErrorMessage } from "@hookform/error-message"
import { ButtonStyles } from "./Button/ButtonStyles"
import { FloatingLabel } from "./Form/FloatingLebel"
import Logo from "./Logo"
import useSiteMetadata from "../hooks/useSiteMetadata";

const Footer = styled.footer`
    background: var(--footerBg);
    color: var(--textNormal);
    display: grid;
    
    .newsletter_signup {
        background: var(--darkSectionBg);
        color: var(--white);
        padding: var(--sectionPadding);
        width: 100%;
        
        & > div {
            display: grid;
            place-items: center;
            grid-template-columns: 1fr;
            //grid-gap: 2rem;
            color: var(--white);
            
            h3, p, span {
                color: var(--white);
                font-size: inherit;
            }
            
            .fade_up {
                display: grid;
                    grid-gap: 1.5rem;
                z-index: 1;
                
                h3, p {
                    margin: 0;
                }
            }
            
            .newsletter_signup_btn_wrapper {
                width: 100%;
                display: flex;
                    align-self: center;
                    justify-self: center;

                form {
                    width: inherit;
                    display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
                        grid-gap: 2rem;
                    
                    button {
                        width: max-content;
                        ${ButtonStyles};
                    }
                }
                
            }
            
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                grid-auto-rows: auto;
                grid-gap: 2rem;
            }

            @media (max-width: 479px) {
                form button {
                    margin: 0 auto;
                }
            }
        }
    }
    
    .contact {
        padding: 24px 0;
        background: var(--white);
        color: var(--black);
        
        & > div {
            display: grid;
            place-items: center;
            gap: 24px; 
        }
        
        .reset_a {
            text-decoration: none;
            color: inherit;
            line-height: 1;
        }
        
        .gatsby-image-wrapper > div[aria-hidden="true"] {
           display: none!important
        }
        
        img {
            margin: 0 auto;
        }
    }
    
    .social_media {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      
        a { 
            display: grid;
            place-items: center;
        }
      
        a:not(:last-child) {
            margin-right: 10px;
        }
        
        svg {
            width: 30px;
            height: auto;
            cursor: pointer;
            border: 1.5px solid var(--black);
            border-radius: 50%;
            transition: background-color .3s ease-out;
            
            path {
                fill: var(--black);
                transition: fill .3s ease-out;
            }
            
            &:hover {
                background: var(--black);

                path {
                    fill: var(--white)!important;
                }
            }
        }
    }
    
    .panodstrony_sign {
        background: #081125;
        text-align: center;
        padding: 6px 0;
        
        .small {
            font-size: 70%;
            color: #cacaca;
            margin: 0 auto;
            text-align: center;
        }
    }   
`;

const BackgroundImage = styled.div`
    display: grid;
    grid-area: 1/1;
    filter: brightness(.35);
`;

const ContentOnBackgroundImage = styled.div`
    display: grid;
    grid-area: 1/1;
    max-width: ${({ narrower }) => narrower && '65ch'};
    margin: ${({ narrower }) => narrower && '0 auto'};
`;

const FooterComponent = ({ narrower }) => {

    const [isSent, setIsSent] = useState('not-sent');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    const onSubmit = (data) => {
        console.log({ data });
        const email = data.email
        const name = data.name;

        const functionPath = `/.netlify/functions/subscribeToNewsletter`;
        const functionOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                email,
                name
            }),
        };

        fetch(functionPath, functionOptions)
            .then(response => {
                if (response.status === 404) {
                    console.log(response.ok);
                    setIsSent('error');
                }
            })
            .then(() => {
                setIsSent('sent');
            })
            .catch(() => {
                setIsSent('error');
            });

        setIsSent('sending');
    }

    return (
        <Footer>
            {locale === 'pl' &&
                <>
                    <BackgroundImage>
                    <StaticImage
                    src="../../static/handshake.jpg"
                    layout="fullWidth"
                    alt=""
                    aspectRatio={4 / 1}
                    formats={["auto", "webp", "avif"]}
                    />
                    </BackgroundImage>
                    <ContentOnBackgroundImage narrower={narrower}>
                        <div className="newsletter_signup">
                            <Container>
                                <SectionHeader darkUnderline>
                                    {t('footer.newsletter.header_line_1')}
                                    {' '}<span className="underlined">{t('footer.newsletter.header_line_2')}</span>
                                    {' '}<span role="img" aria-label="emoji">📨</span>
                                </SectionHeader>
                                <div className="fade_up">
                                    <h3>{t('footer.newsletter.paragraph1')}</h3>
                                    <p>{t('footer.newsletter.paragraph2')}</p>
                                    <div className="newsletter_signup_btn_wrapper">
                                    {isSent === 'not-sent' &&
                                        <form>
                                            <FloatingLabel htmlFor="name" className={errors.name && 'error'}>
                                                <input type="text" name="name" placeholder="Imię"
                                                    {...register("name", {
                                                    required: t('valform:valform_utils.missing_field'),
                                                    maxLength: {
                                                    value: 40,
                                                    message: `${t('valform:valform_utils.max_length')} 40`
                                                    }})}
                                                />
                                                <span>Imię</span>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="name"
                                                    message={t('valform:valform_utils.missing_field')}
                                                    render={({message}) => <small>{message}</small>}
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel htmlFor="email" className={errors.email && 'error'}>
                                                <input type="text" name="email" placeholder="Adres e-mail"
                                                    {...register("email", {
                                                    required: t('valform:valform_utils.missing_field'),
                                                    maxLength: {
                                                    value: 40,
                                                    message: `${t('valform:valform_utils.max_length')} 40`
                                                    },
                                                    pattern: {
                                                    // value: /\S+@\S+\.\S+/,
                                                    // value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                    value: /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                    message: t('valform:valform_utils.incorrect_email')
                                                }})} />
                                                <span>Adres e-mail</span>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    message={t('valform:valform_utils.missing_field')}
                                                    render={({message}) => <small>{message}</small>}
                                                />
                                            </FloatingLabel>
                                            <button type="button" onClick={handleSubmit(onSubmit)}>Zapisuję się!</button>
                                        </form>
                                    }
                                    {isSent === 'sent' &&
                                        <p>Dziękuję za dołączenie! <span role="img" aria-label="emoji"> 🎉</span> Na Twoją skrzynkę powędrowała wiadomość z potwierdzeniem zapisu. Pamietaj, aby kliknąć w przycisk potwierdzajacy zapis. Bez tego nie będę mógł wysyłać do Ceibie maili.</p>
                                    }
                                    {isSent === 'error' &&
                                        <div>
                                            <p>Coś poszło nie tak. Przepraszam za uniedogodnienia. Na newsletter możesz zapisać się także pod <a href="https://newsletter.panodstrony.pl">tym linkiem</a></p>
                                            <p>Będę także niesamowicie wdzięczny, jeśli przejdziesz na stronę "kontkat" i poinformujesz mnie o tym błędzie, abym mógł jak najszybciej go naprawić. Z góry dziękuję i przepraszam!</p>
                                        </div>
                                    }
                                    {isSent === 'sending' &&
                                        <Loader text="Zapisywanie..." />
                                    }
                                </div>
                            </div>
                            </Container>
                        </div>
                    </ContentOnBackgroundImage>
                </>
            }
            <div className="contact">
                <Container noPadding>
                    <Logo href="/" />
                    <div className="social_media">
                        <a href={useSiteMetadata().facebook} aria-label="Facebook account">
                            <FacebookIcon />
                        </a>
                        <a href={useSiteMetadata().twitter} aria-label="Twitter account">
                            <TwitterIcon />
                        </a>
                        <a href={useSiteMetadata().instagram} aria-label="Linkedin account">
                            <InstagramIcon />
                        </a>
                    </div>
                    <a className="reset_a" href="mailto:kontakt@panodstrony.pl">kontakt@panodstrony.pl</a>
                </Container>
            </div>
            <div className="panodstrony_sign">
                <p className="gray small">panodstrony.pl © {new Date().getFullYear()}</p>
            </div>
        </Footer>
    )
};

export default FooterComponent;
import React from 'react';
import styled from "styled-components"
import LogoSvg from '../../static/logo_with_lines.svg';
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const StyledLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        height: 40px;
        width: auto;
    }
    
    a {
        display: flex;
        align-items: center;
    }
`;

export default function Logo({ href = "/"}) {

    const { i18n } = useTranslation();
    const locale = i18n.language;

    return (
        <StyledLogoWrapper>
            <Link to={href}
                  aria-label={locale === 'pl' ? 'Nawiguj do strony głównej' : 'Navigate to homepage'}
                  tabIndex="0"
            >
                <LogoSvg />
            </Link>
        </StyledLogoWrapper>
    )
}
import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.h2`
    position: relative;
        z-index: 1;
    display: inline-block;
    margin: ${({ biggerMarginBottom }) => biggerMarginBottom ? '0 auto 2.5rem' : '0 auto 1.5rem'};
    width: ${({ center }) => center ? '100%' : 'fit-content'};
    color: ${({ dark }) => dark ? 'var(--black)' : 'var(--white)'};
        text-align: ${({ center}) => center ? 'center' : 'unset'};
        font-size: clamp(1.25rem, 7vw, 2.1rem);
    
    //clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    
    .underlined {
        position: relative;
        white-space: pre-wrap;
        z-index: -1;
        
        &::before {
            position: absolute;
            content: '';
            left: 0;
            bottom: 15%;
            height: 30%;
            width: 100%;
            z-index: -1;
            background: ${({ darkUnderline }) => darkUnderline ? 'var(--darkUnderline)' : 'var(--lightUnderline)'};
        }
    }
    
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const SectionHeader = ({ children, align_center, mbx2, center, biggerMarginBottom, dark, darkUnderline }) => {

    // useEffect(() => {
    //     const H1 = gsap.utils.toArray('.heading1');
    //     const startAndEnd = '-30% 85%'
    //     H1.forEach((el, index) => {
    //         gsap.to(H1[index], {
    //             ease: "linear",
    //             scrollTrigger: {
    //                 trigger: H1[index],
    //                 start: startAndEnd,
    //                 end: startAndEnd,
    //                 onEnter: () => gsap.to(H1[index], { clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)', duration: 1 }),
    //                 onLeaveBack: () => gsap.to(H1[index], { clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)', duration: 1 }),
    //         }});
    //     });
    // }, []);

    return (
        <StyledHeader
            className="heading1"
            center={center}
            dark={dark}
            darkUnderline={darkUnderline}
            biggerMarginBottom={biggerMarginBottom}
        >
            {children}
        </StyledHeader>
)
};

export default SectionHeader;
import React, { useState } from "react"
import { gsap } from 'gsap';
import styled from 'styled-components';

const StyledCookieSWrapper = styled.div`
    visibility: ${({ hidden }) => hidden && 'hidden'};
    position: fixed;
        right: 0;
        bottom: 0;
        z-index: 10;
    display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    width: 100%;
    padding: .5em 1em;
    text-align: start;
    color: var(--white);
    background: var(--black);
    //opacity: 0;
    
    //@media (max-width: 768px) {
    //    padding: .9em 1em;
    //}
    //
    @supports (backdrop-filter: blur(15px)) {
        .navbar_active {
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
            background: #00000099;
        }
    }

    p {
        display: inline-block;
        padding: 0;
        margin: 0;
        font-size: small;
    }

    button {
        display: inline-block;
        border: none;
        background: none;
        text-underline: none;
        padding: .5em 1em;
        background: white;
        border-radius: 6px;
        cursor: pointer;
    }

`;

const CookiesNotification = () => {

    const windowGlobal = typeof window !== 'undefined' && window;
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    const cookiesLocalStorage = windowGlobal?.localStorage?.getItem('cookies');

    const handleClick = () => {
        setCookiesAccepted(true);
        windowGlobal.localStorage.setItem('cookies', 'accepted')
    }

    // React.useEffect(() => {
    //     gsap.to('.cookies_consent', { autoAlpha: 1, duration: 1 } )
    // }, [])

    return (
        <>
            {cookiesLocalStorage ? <></> :
                <StyledCookieSWrapper className="cookies_consent" hidden={cookiesAccepted}>
                    <p>🍪 Ta strona używa Google Analytics.</p>
                    <button onClick={handleClick}>
                        OK
                    </button>
                </StyledCookieSWrapper>
            }
        </>
    )
}

export default CookiesNotification;
import i18next from 'i18next';
import { initReactI18next } from "react-i18next"

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'pl',
        resources: {
            pl: {
                homepage: require('./locales/pl/homepage.json'),
                valform: require('./locales/pl/valform.json')
            },
            en: {
                homepage: require('./locales/en/homepage.json'),
                valform: require('./locales/en/valform.json')
            }
        },
        ns: ['homepage, valform'],
        defaultNS: 'homepage',
        returnObjects: true,
        // debug: process.env.NODE_ENV === 'development',
        // debug: false,
        interpolation: {
        escapeValue: false, // not needed for react!!
        },
        react: {
            useSuspense: true,
        },
    });

i18next.languages = ['pl', 'en'];

export default i18next;
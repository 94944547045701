import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from "react-i18next"

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`
const StyledSubmittingSection = styled.div`
    display: grid;
        place-items: center;
    margin: 0 auto;
    
    p {
        font-size: x-large;
        color: white;
        font-weight: bold;
    }
`;

const StyledLoader = styled.div`
    border: ${({ multiColor }) => multiColor ? '16px solid var(--loaderColor)' : '16px solid white'}; 
    border-top: 16px solid var(--advSvg); 
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: ${spin} 2s linear infinite;
`;

const Loader = ({ multiColor, text }) => {

    const { t } = useTranslation(['valform']);

    return (
        <StyledSubmittingSection>
            <StyledLoader multiColor={multiColor}/>
            {text && <p>{text}...</p>}
            {!text && <p>{t('valform:valform_utils.sending')}...</p>}
        </StyledSubmittingSection>
    )
};

 export default Loader;
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import capitalizeString from "../utils/capitalizeString"

function SEO({ description, lang, meta, title, image, pathname, keywords, blogPost, pageName, pageUrl, jsonLdMainType, jsonLdUrl, jsonLdHeadline, jsonLdDescription, jsonLdDatePublished, jsonLdDateModified }) {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        defaultImage
                        logo
                    }
                }
                defaultImage: file(name: {eq: "laptop"}) {
                    childImageSharp {
                        resize(width: 1200) {
                            src
                            height
                            width
                        }
                    }
                }
            }
        `
    );

    const metaDescription = description || data.site.siteMetadata.description;
    // const metaImage =
    //     image && image.src
    //         ? `${data.site.siteMetadata.siteUrl}${image.src}`
    //         : `${data.site.siteMetadata.siteUrl}${data.site.defaultImage.childImageSharp.resize.src}`;
    const metaImage = blogPost ? `${data.site.siteMetadata.siteUrl}${image.src}` : `${data.site.siteMetadata.siteUrl}${data.defaultImage.childImageSharp.resize.src}`;
    const canonical = blogPost ? jsonLdUrl : pageUrl;

    return (
        <Helmet
            htmlAttributes={{
                lang,
                }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
                {
                    name: "keywords",
                    content: keywords.join(",") || SEO.keywords.join(","),
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: canonical,
                },
                {
                    property: `fb:app_id`,
                    content: '652869365664878',
                },
                {
                    property: `og:site_name`,
                    content: title,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: data.site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image:alt`,
                    content: title,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: 'image',
                    content: metaImage,
                },
                {
                    property: `og:image`,
                    content: metaImage,
                },
                {
                    name: `twitter:image`,
                    content: metaImage,
                },
                {
                    property: 'og:image:width',
                    content: blogPost ?  image.width : data.defaultImage.childImageSharp.resize.width
                },
                {
                    property: 'og:image:height',
                    content: blogPost ?  image.height : data.defaultImage.childImageSharp.resize.height
                },
            ]}
        >
            <link rel="canonical"
                  key={canonical}
                  href={canonical}
            />
            <script type="application/ld+json">
                {blogPost ?
                    `[
                        {
                            "@context": "https://schema.org",
                            "@type": "BlogPosting",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "${jsonLdUrl}"
                            },
                            "headline": "${jsonLdHeadline}",
                            "description": "${jsonLdDescription}",
                            "image": "${image}",
                            "datePublished": "${jsonLdDatePublished}",
                            "dateModified": ${jsonLdDateModified ? `"${jsonLdDateModified}"` : `"${jsonLdDatePublished}"`},
                            "author": {
                                "@type": "Person",
                                "name": "Adam Leszczyński",
                                "url": "${data.site.siteMetadata.siteUrl}"
                            },
                            "publisher": {
                                "@type": "Person",
                                "name": "Adam Leszczyński",
                                "logo": "${data.site.siteMetadata.logo}",
                                "url": "${data.site.siteMetadata.siteUrl}"
                            }
                        },
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "panodstrony",
                            "url": "${data.site.siteMetadata.siteUrl}",
                            "logo": "${data.site.siteMetadata.logo}"
                        },
                        {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                                "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Blog Posty",
                                "item": "${data.site.siteMetadata.siteUrl}/blog"
                            },{
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${jsonLdHeadline}",
                                "item": "${jsonLdUrl}"
                            }]
                        }
                    ]`
                        :
                    `[
                        {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "${pageUrl}"
                            }
                        },
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "panodstrony",
                            "url": "${data.site.siteMetadata.siteUrl}",
                            "logo": "${data.site.siteMetadata.logo}"
                        },
                        {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                                "itemListElement": {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "${pageName === '' ? 'Home' : capitalizeString(pageName)}",
                                "item": "${pageUrl}"
                            }
                        }
                    ]`
                }
            </script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    keywords: ['jamstack', 'headless cms', 'szybkie strony internetowe'],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    pathname: PropTypes.string,
};

export default SEO;



// {
//
//
// },




// https://storageapi.fleek.co/adiusz-team-bucket/logo_minimalPadding.png
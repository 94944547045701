import { css } from "styled-components"

export const ButtonStyles = css`
    color: ${({ filled }) => filled ? "var(--white)" : "var(--textTitle)"};
        background: ${({ filled }) => filled ? "var(--black)" : "var(--white)"};
        text-align: center;
        font-weight: 800;
        text-decoration: none;
        border: ${({ darkBorder }) => darkBorder ? '2px solid var(--black)' : '2px solid transparent'};
        border-radius: .2rem;
    font-size: clamp(.8rem, 3.5vw, 1rem);
    cursor: pointer;
    box-shadow: 0 0 38.8px -26px var(--advSvg);
    padding: .5em 1.5em;
    transition: box-shadow .3s ease-in-out, color .3s ease-out, background .3s ease-out, border .3s ease-out;
        will-change: color, background;
        opacity: ${({ invisibleforanimation }) => invisibleforanimation ? '0' : 'unset'};
    margin-top: ${({ marginTop }) => marginTop && '2rem'};
    width: ${({ widthTooBig }) => widthTooBig && 'max-content'};
    
    a {
        color: inherit;
        text-decoration: inherit;
    }
    
    &:hover {
        color: ${({ filled }) => filled ? "var(--black)" : "var(--primary)"};
        background: ${({ filled }) => filled ? "var(--white)" : "var(--white)"};
        border: ${({ filled }) => filled ? '2px solid var(--primary)' : '2px solid var(--white)'};
    }
    
    &:focus {
        outline: 2px solid var(--primary);
    }
    
    //&:hover {
    //    box-shadow: 0 0 38.8px -5px var(--advSvg);
    //}
`;

    // ${ShadowOnHover};


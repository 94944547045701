import styled from 'styled-components';
// instruction:
// 1. FloatingLabel is a parent label, it takes 2 children: input and span,
// 2. Input must have placeholder values, even though them have opacity set to 0.

export const FloatingLabel = styled.label`
    position: relative;

    input, textarea {
        position: relative;
        width: 100%;
        color: var(--white);
        padding: .6em;
        border: 1px solid #818181;
        border-radius: 5px;
        background: rgba(128, 128, 128, 0.84);
        appearance: none;
        -webkit-appearance: none;
        transition: border-color .3s ease-out;
        will-change: border-color;
        @supports (backdrop-filter: blur(15px)) {
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
            background: rgba(128, 128, 128, 0.57);
        }

        &::placeholder {
            opacity: 0;
        }
        
        &:focus, &:focus-visible, &:focus-within {
            outline: none;
            // border-bottom: 2px solid var(--primary);
        }
        
        &:after {
            position: absolute;
                bottom: 0;
                left: 0;
                content: '';
            height: 2px;
            width: 100%;
            background: var(--primary);
            opacity: 0;
            transition: opacity .3s ease-out;
        }
        
        &:focus-within:after {
            opacity: 1;
        }
    }
    
    &:focus-within > span,
    input:not(:placeholder-shown) + span,
    textarea:not(:placeholder-shown) + span {
        color: #cdcdcd!important;
        transform: translate(-19%, -180%) scale(0.7)
    }
    
    &:focus-within > input,
    input:not(:placeholder-shown),
    textarea:not(:placeholder-shown) {
        border-color: transparent;
    }

    span {
        position: absolute;
            top: 50%;
            left: 1em;
            transform: translate(0, -50%);
            //transform-origin: center center;
        width: 100%;
        transition: transform .4s ease-out, color .3s ease-out;
        //transform-origin: left;
        will-change: transform, color;
        pointer-events: none;
    }

    small {
        position: absolute;
        left: 2px;
        bottom: -17px;
        display: flex;
        align-items: center;
        color: red;
        text-align: left;
        font-size: .6rem;
        width: 100%;

        svg {
            max-height: 15px;
            max-width: 15px;
            margin-right: 4px;
        }
    }

    &.error input {
        border: 1px solid var(--red);
    }
    
`